<template>
  <div
    :class="`card-item-tag__${type.toLowerCase()} bg-secondary text-2xs inline-flex items-center font-semibold w-28 h-7 rounded-full text-white justify-center`"
  >
    {{ $t(type) }}
  </div>
</template>

<script>
import { ProjectStatus } from "@/constants/status.constant";
export default {
  props: {
    type: { type: String, required: true },
  },
  data() {
    return {
      types: ProjectStatus,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-item-tag__new {
  background: #dddb51;
}
.card-item-tag__started {
  background: #00A0DD;
  ;
}
.card-item-tag__minting {
  background: #1c9bb3;
}
.card-item-tag__on_presale {
  background: #ACCB39;
}

.card-item-tag__on_sale {
  background: #ACCB39;
;
}
.card-item-tag__finished {
  background: #ff0000;
}

.card-item-tag__ready {
  background: #75C1DD;
  ;
}

.card-item-tag__created {
  background: #58b31c;
}

.card-item-tag__error {
  background: red;
}
</style>
