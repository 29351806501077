export const ProjectStatus = {
  NEW: {
    text: "New",
  },
  READY: {
    text: "Ready",
  },
  COMING_SOON: {
    text: "Coming soon",
  },
  CREATED: {
    text: "Contract created",
  },
  FINISHED: {
    text: "END",
  },
  ON_PRESALE: {
    text: "Minting now",
  },
  ON_SALE: {
    text: "Minting now",
  },
};
